/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-June-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Manual Task Addtion 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |    AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*    2287        | 24-06-204 | Shiyam kumar |  Validate Date picker and made changes
*
*
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { Backdrop, Box, Checkbox, Chip, DialogContentText, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { addTask, getDependenciesByTaskIdAndProjectId, getNewDependenciesByTaskIdAndProjectId, getTasksByProjectIdAndTaskId } from '../../services/TaskService';
import { getResourceByDateRange } from '../../services/ResourceService';
import moment from 'moment';
import CustomDialog from '../../pages/Dialog';
import gmslogo from "../../assets/logo.gif";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { reschedule } from '../../services/ProjectService';
import { getConfiguredDataByKey } from '../../services/TradeConfigurationService';
import { weekdays } from '../../utils/Dateutils';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const AddTaskForm = ({ open, onClose, projectId, id }) => {
    // const [open, setOpen] = React.useState(props?.openDialog);
    const [taskData, setTaskData] = React.useState<any>({
        'taskName': '',
        'taskType': '',
        'dependencies': '',
        'phase': '',
        'startTime': '',
        'endTime': '',
        'assignees': [],
        'resources': '',
        'taskId': '',
        'wbs': '',
        'queries': '',
        'tools': '',
        'estimatedHours': '',
        'estimatedDays': '',
        'otherDependencies': ''
    });
    const [taskDependencies, setTaskDependencies] = React.useState<any>([]);
    const [tasks, setTasks] = React.useState<any>([]);
    const [minDate, setMinDate] = React.useState<any>();
    const taskTypes: any = ['standard', 'confirmation', 'nonExecuted', 'execution', 'dynamicDuration-distance',
        'fixedDuration', 'dynamicDuration-area', 'dynamicDuration-parent', ' dynamicDuration'
    ]
    const [count, setCount] = React.useState(0);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [taskId, setTaskId] = React.useState<any>();
    const taskStatus = ['active', 'scheduled'];
    const [show, setShow] = React.useState<any>(true);
    const [resourceList, setResourceList] = React.useState<any>([] as any[]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [isDisabledAssignees, setIsDiabledAssignees] = React.useState<any>(true);
    const [isDisbledTime, setIsDisabledTime] = React.useState<any>(true);
    const [taskNewDependencies, setTaskNewDependencies] = React.useState<any>([]);
    const [warning, setWarning] = React.useState(false);
    const [names, setNames] = React.useState<any>([]);
    const [estimatedHours, setEstimatedHours] = React.useState<any>();
    const [estimatedDays, setEstimatedDays] = React.useState<any>();
    const [dependencies, setDependencies] = React.useState<any>([]);
    const [otherDependencies, setOtherDependencies] = React.useState<any>([]);
    const [workingDays, setWorkingDays] = React.useState<any>([]);
    const [startTime, setStartTime] = React.useState<any>();
    const [endTime, setEndTime] = React.useState<any>();


    const handleClose = () => {
        onClose();
        setTaskData({
            'taskName': '',
            'taskType': '',
            'dependencies': '',
            'phase': '',
            'startTime': '',
            'endTime': '',
            'assignees': [],
            'resources': '',
            'taskId': '',
            'wbs': '',
            'queries': '',
            'tools': '',
            'estimatedHours': '',
            'estimatedDays': '',
            'otherDependencies': '',
        });
        setNames([]);
        setShow(true);
        setIsDiabledAssignees(true);
        setIsDisabledTime(true);
        setWarning(false);
        setDependencies([]);
        setOtherDependencies([]);
        setCount(0);
        setTaskId(undefined);
    };

    const showWarning = () =>{
        setCount(count + 1);
        let validate = validation();
        if (validate === true) {
            setWarning(true);
        }
    }

    const addNewTask = async () => {
        setWarning(false);
        setIsLoading(true);
        setCount(count + 1);
        let validate = validation();
        if (validate === true) {
            Object.assign(taskData, { 'project': projectId });
            const response = await addTask(taskData, accessToken);
            if (response.status === 200 || response.status === 201) {
                setError(false);
                setIsLoading(false);
                setOpenDialog(true);
                setSuccess(true);
                setTaskId(undefined);
            } else {
                setIsLoading(false);
                setOpenDialog(true);
                setError(true);
            }
        }
        else {
            setIsLoading(false);
            setError(true);
        }
    };

    const handleChange = (event: any) => {
        if (event.target.name === 'taskId') {
            setTaskId(event.target.value);
            setTaskData({
                ...taskData, taskId: (event.target.value).taskId,
                wbs: (event.target.value).wbs, phase: (event.target.value).phase
            })
            setMinDate(dayjs((event.target.value).startTime).format('YYYY-MM-DD'));
            setIsDisabledTime(false);
            getDependencies((event.target.value).taskId);
            getNewDependencies((event.target.value).taskId);
        }
        else {
            setTaskData({
                ...taskData,
                [event.target.name]: event.target.value
            });
        }


    };

// dayjs.extend(isSameOrAfter);
// dayjs.extend(isSameOrBefore);

const WORK_START_HOUR = 9; // 9 AM
const WORK_END_HOUR = 17; // 5 PM

const calculateWorkingHours = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  let start = startDate;
  let end = endDate;

  if (start.isAfter(end)) return 0;

  let totalWorkingHours = 0;

  while (start.isBefore(end)) {
    if (start.day() !== 0 && start.day() !== 6) { // Check if it's a weekday
      const startOfWorkday = start.hour(startTime.hour()).minute(0).second(0);
      const endOfWorkday = start.hour(endTime.hour()).minute(0).second(0);

      if ((start.isSame(startOfWorkday) || start.isAfter(startOfWorkday)) && (start.isSame(endOfWorkday) || start.isBefore(endOfWorkday))) {
        totalWorkingHours += Math.min(endOfWorkday.diff(start, 'hour', true), end.diff(start, 'hour', true));
      } else if (start.isBefore(startOfWorkday)) {
        totalWorkingHours += Math.min(endOfWorkday.diff(startOfWorkday, 'hour', true), end.diff(startOfWorkday, 'hour', true));
      }
    }
    start = start.add(1, 'day').startOf('day');
  }

  return totalWorkingHours;
};



    const shouldDisableTime: any = (value:any,view:any,row:any) => {
        if(row != undefined){
            return (view === 'minutes' && value.minute() <= dayjs((row?.startTime)?.replace(/Z/g, '')).minute() && value.hour() === dayjs((row?.startTime)?.replace(/Z/g, '')).hour()
                && value.date() === dayjs((row?.startTime)?.replace(/Z/g, '')).date() && value.month() === dayjs((row?.startTime)?.replace(/Z/g, '')).month() && value.year() === dayjs((row?.startTime)?.replace(/Z/g, '')).year())
                || (value.hour() >= endTime.hour() && value.minute() > endTime.minute()) || value.hour() > endTime.hour() || value.hour() < startTime.hour()
        }else{
            return (view === 'minutes' && value.minute() < startTime.minute()) ||(value.hour() >= endTime.hour() && value.minute() > endTime.minute()) || value.hour() > endTime.hour() || value.hour() < startTime.hour()
        }
    };

    const shouldDisableStartTime:any = (value:any,view:any,row:any) =>{
        return (view === 'minutes' && value.minute() < startTime.minute()) || (value.hour() >= endTime.hour() && value.minute() > endTime.minute()) || value.hour() > endTime.hour() || value.hour() < startTime.hour()
    }


    const shouldDisableDates: any = (value:any,view:any,row:any) => {
        return !workingDays.includes(value.day());
    };

    const onChangeDependencies = (e: any) => {
        const {
            target: { value },
        } = e;
        if (e.target.name === "dependencies") {
            let dates: any = [];
            let Dependencies: any = [];
            value.map((res: any) => {
                Dependencies.push(res.taskId);
                dates.push(new Date((res.endTime)?.replace(/Z/g, '')));
            })
            setShow(false);
            setDependencies(value);
            Object.assign(taskData, { startTime: dateFormatter(new Date(Math.max(...dates))) });
            setTaskData({ ...taskData, dependencies: Dependencies.map(String).join(';') });
        }
        else if (e.target.name === "otherDependencies") {
            setTaskData({ ...taskData, otherDependencies: typeof value === 'string' ? value.split(';').map(String).join(';') : value.map(String).join(';') });
            setOtherDependencies(value);
        }
    }


    const validation = () => {
        let validated = true;
        if (taskData.taskId === '') {
            validated = false;
        }
        // if (taskData.wbs === '') {
        //     validated = false;
        // }
        if (taskData.taskName === '') {
            validated = false;
        }
        if (taskData.taskType === '') {
            validated = false;
        }
        if (taskData.startTime === '') {
            validated = false;
        }
        if (taskData.endTime === '') {
            validated = false;
        }
        // if (taskData.resources === '') {
        //     validated = false;
        // }
        if (taskData.assignees.length === 0) {
            validated = false;
        }

        if(taskData.startTime >= taskData.endTime){
            validated = false;
        }
        
        return validated;
    }



    const handleAssigneesChange = (event: any) => {
        let value = (event.target.value);
        let resIds: any = [];
        let resource: any = [];
        value.map((res: any) => {
            resIds.push(res.resourceUid);
            resource.push(res.designation);
        })
        setNames(value);
        taskData.assignees = resIds;
        taskData.resources = resource.map(String).join(';')
    };

    const getTaskListByProjectIdAndTaskId = async () => {
        let result = await getTasksByProjectIdAndTaskId(projectId, id, accessToken);
        let filteredTasks = (result.data).filter((task: any) => (task.wbs).split(".").length > 2);
        setTasks(filteredTasks);
    }

    const getDependencies = async (taskId: any) => {
        let result = await getDependenciesByTaskIdAndProjectId(projectId, taskId, taskStatus, accessToken);
        let filteredTasks = (result.data).filter((task: any) => (task.wbs).split(".").length > 2);
        setTaskDependencies(filteredTasks);
    }

    const getNewDependencies = async (taskId: any) => {
        let result = await getNewDependenciesByTaskIdAndProjectId(projectId, taskId, taskStatus, accessToken);
        let filteredTasks = (result.data).filter((task: any) => (task.wbs).split(".").length > 2);
        setTaskNewDependencies(filteredTasks);
    }
    const dateFormatter = (value: any) => {
        const date = new Date(value);
        const month = date.getMonth().toString();
        return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T"
            + ((date.getHours()).toString().length === 1 ? "0" + (date.getHours()).toString() : (date.getHours()).toString()) + ":" + ((date.getMinutes()).toString().length === 1 ? "0" + (date.getMinutes()).toString() : (date.getMinutes()).toString()) + ":00.000Z";
    }

    const onDateChange = (e: any, field: any) => {
        if (field === 'startTime') {
            Object.assign(taskData, { startTime: dateFormatter(e.$d), dependencies: '' })
            setShow(false);
            setDependencies([]);
            // setTaskData({ ...taskData, dependencies: '' });
        }
        else if (field === 'endTime') {
            Object.assign(taskData, { endTime: dateFormatter(e.$d) })
            setIsDiabledAssignees(false);
            handleOpen();
            setNames([]);
        }
        let estimatedHours = 0;

        if (taskData?.startTime !== '' && taskData?.endTime !== '') {
            if(field === 'endTime'){
                // setStartTime(dayjs.utc(row.startTime).format('YYYY-MM-DD HH:mm:ss[Z]'))
            // const  rowStartTime  = dayjs.utc(row.startTime).format('YYYY-MM-DDTHH:mm:ss[Z]');
            const rowStartTime = dayjs((taskData?.startTime)?.replace(/Z/g, ''));
            //const estimatedHours =dayjs(event.$d).diff(startTime === undefined ? rowStartTime : startTime, 'hour', true); 
            estimatedHours = calculateWorkingHours(rowStartTime, dayjs(e.$d));
            }else{
                const rowEndTime = dayjs((taskData?.endTime)?.replace(/Z/g, ''));
            //const estimatedHours =dayjs(event.$d).diff(startTime === undefined ? rowStartTime : startTime, 'hour', true); 
            estimatedHours = calculateWorkingHours(dayjs(e.$d), rowEndTime);
            }
            Object.assign(taskData, { estimatedHours: estimatedHours })
            Object.assign(taskData, { estimatedDays: (moment((taskData?.endTime)).diff(moment(taskData.startTime), 'minutes') / 1440).toFixed(2) });
            setEstimatedDays(estimatedHours);
            setEstimatedHours((moment((taskData?.endTime)).diff(moment(taskData.startTime), 'minutes') / 60).toFixed(2));
        }
    }

    const getAssignees = async () => {
        if (taskData.startTime !== '' && taskData.endTime !== '') {
            let result = await getResourceByDateRange(taskData.startTime, taskData.endTime, accessToken);
            setResourceList(result.data);
        }
    }
    const handleOpen = async () => {
        let result = await getResourceByDateRange(taskData.startTime, taskData.endTime, accessToken);
        setResourceList(result.data);
    }

    const handleLoadingClose = () => {
        setIsLoading(false);
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        setOpenDialog(false);
        setCount(0);
        // setOpen(false);
        if (!error) {
            handleClose();
        }

    }

    const workSchedule = async () =>{
        const result = await getConfiguredDataByKey("workingHours", accessToken);
        if(result.data !== undefined && (result.status === 200 || result.status === 201)){
            var object = JSON.parse(result.data);
            setStartTime(dayjs(object.startTime, 'HH:mm'));
            setEndTime(dayjs(object.endTime, 'HH:mm'));
        }
        const response = await getConfiguredDataByKey("workingDays", accessToken);
        if(response.data !== undefined && (response.status === 200 || response.status === 201)){
            let tenantWokringDays = response.data.split(",");
            weekdays.map((day:any)=>{
                if(tenantWokringDays.includes(day)){
                    workingDays.push(weekdays.indexOf(day));
                }
            })
            //setWorkingDays(response.data.split(",").map((num:any) => parseInt(num)));
        }
    }

    React.useEffect(() => {
        getTaskListByProjectIdAndTaskId();
        workSchedule();
    }, [])
    return (
        <React.Fragment>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add Task
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label" error={taskData?.taskId === '' && count !== 0}>Task Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="taskId"
                                    value={taskId !== undefined ? taskId : ''}
                                    error={taskData?.taskId === '' && count !== 0}
                                    label="Task Id"
                                    onChange={(e) => handleChange(e)}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                                >
                                    {tasks?.map(task => {
                                        return (
                                            <MenuItem value={task}>{task?.taskId}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <TextField
                                    id="demo-helper-text-aligned"
                                    label="Wbs"
                                    name="wbs"
                                    size="small"
                                    value={taskData?.wbs}
                                    // error={(taskData?.wbs === '' && count !== 0)}
                                    fullWidth
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Task Name"
                                name="taskName"
                                size="small"
                                error={(taskData?.taskName === '' && count !== 0)}
                                fullWidth
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label" error={(taskData?.taskType === '' && count !== 0)}>Task Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="taskType"
                                    value={taskData?.taskType}
                                    error={(taskData?.taskType === '' && count !== 0)}
                                    label="Task Type"
                                    onChange={(e) => handleChange(e)}
                                >
                                    {taskTypes?.map((types, index) => {
                                        return (
                                            <MenuItem key={index} value={types}>{types}</MenuItem>
                                        )
                                    })}


                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-multiple-checkbox-label">Dependencies</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={dependencies}
                                    //error={(taskData?.dependencies === '' && count !== 0)}
                                    label="Dependencies"
                                    name="dependencies"
                                    disabled={isDisbledTime}
                                    onChange={(e) => onChangeDependencies(e)}
                                    input={<OutlinedInput label="Dependencies" />}
                                    renderValue={(selected) =>{
                                        let ids:any = []
                                        selected.map((value:any , index) => (
                                           ids.push(value.taskId)
                                        ))
                                        return ids.join(",");
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                                >
                                    {taskDependencies?.map((dependencies: any) => {
                                        return (
                                            <MenuItem value={dependencies}>{dependencies?.taskId}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Phase"
                                name="phase"
                                size="small"
                                value={taskData?.phase}
                                // error={(taskData?.phase === '' && count !== 0)}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        // format='DD MMM YYYY'
                                        label="Task Start Time"
                                        slotProps={{
                                            textField: {
                                                fullWidth: true, size: "small",
                                                error: (taskData?.startTime === '' && count !== 0)
                                            }
                                        }}
                                        disablePast
                                        name="startTime"
                                        closeOnSelect={false}
                                        //onClose={(e)=>console.log}
                                        //disabled={ids.includes(index) ? true : false}
                                        shouldDisableDate={shouldDisableDates}
                                        shouldDisableTime={(value, view) => shouldDisableStartTime(value, view, taskData?.startTime)}
                                        value={taskData?.startTime === '' ? null : dayjs((taskData?.startTime)?.replace(/Z/g, ''))}
                                        onChange={(e) => onDateChange(e, 'startTime')} />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        // format='DD MMM YYYY'
                                        label="Task End Time"
                                        slotProps={{
                                            textField: {
                                                fullWidth: true, size: "small",
                                                error: (taskData?.endTime === '' && count !== 0 && !show || taskData.startTime >= taskData.endTime && count !== 0 && !show)
                                            }
                                        }}
                                        minDateTime={dayjs((taskData?.startTime)?.replace(/Z/g, ''))}
                                        shouldDisableTime={(value, view) => shouldDisableTime(value, view, taskData)}
                                        disablePast
                                        name="endTime"
                                        disabled={show}
                                        closeOnSelect={false}
                                        onClose={getAssignees}
                                        // value={dayjs((taskData?.startTime)?.replace(/Z/g, ''))}
                                        onChange={(e) => onDateChange(e, 'endTime')} />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-multiple-checkbox-label" error={(taskData.assignees.length === 0 && count !== 0 && !isDisabledAssignees)}>Assignees</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={names}
                                    onChange={handleAssigneesChange}
                                    disabled={isDisabledAssignees}
                                    error={(taskData.assignees.length === 0 && count !== 0 && !isDisabledAssignees)}
                                    input={<OutlinedInput label="Assignees" />}
                                    renderValue={(selected) =>
                                        selected.map((value, index) => (
                                            <Chip key={index} label={value?.name} />
                                        ))
                                    }
                                >
                                    {resourceList?.map((resource, index) => (
                                        <MenuItem key={index} value={resource}>
                                            {resource?.name}({resource?.designation})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Resources"
                                name="resources"
                                value={taskData?.resources}
                                size="small"
                                fullWidth
                                disabled
                            />
                        </Grid>

                        {/* <Grid item xs={6}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Queries"
                                name="queries"
                                size="small"
                                value={taskData?.queries}
                                fullWidth
                                onChange={(e) => handleChange(e)}

                            />
                        </Grid> */}
                         <Grid item xs={6}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-multiple-checkbox-label">New Dependencies</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={otherDependencies}
                                    //error={(taskData?.otherDependencies === '' && count !== 0)}
                                    label="Other Dependencies"
                                    name="otherDependencies"
                                    disabled={isDisbledTime}
                                    onChange={(e) => onChangeDependencies(e)}
                                    input={<OutlinedInput label="Other Dependencies" />}
                                    renderValue={(selected) => selected.join(",")}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                                >
                                    {taskNewDependencies?.map((dependencies: any, index:any) => {
                                        return (
                                            <MenuItem key= {index} value={dependencies?.taskId}>{dependencies?.taskId}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Tools"
                                name="tools"
                                size="small"
                                value={taskData?.tools}
                                fullWidth
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Estimated Hours"
                                name="estimatedHours"
                                size="small"
                                fullWidth
                                value={taskData?.estimatedHours}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Estimated Days"
                                name="estimatedDays"
                                size="small"
                                value={taskData?.estimatedDays}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        onClick={handleLoadingClose}
                    >
                        <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                    </Backdrop>
                </DialogContent>
                <DialogActions>
                    {taskData.otherDependencies.length === 0 ? <Button onClick={addNewTask} className='sucessButton' size="medium">
                        Save
                    </Button> : <Button onClick={showWarning} className='sucessButton' size="medium">
                        Save
                    </Button>}
                    <Button onClick={handleClose} className='dangerButton' size='medium'>
                        Cancel
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <Dialog open={warning}>
                    <DialogTitle className='warning'>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <WarningAmberIcon sx={{ fontSize: "50px", color: "white" }} />
                            </Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'}>
                                Warning
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <Box paddingLeft={8} paddingRight={8}>
                        <DialogContent>
                            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Remaining tasks will reschedule</DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button onClick={handleClose} variant='contained' sx={{ backgroundColor: "#df3333ed" }}>Close</Button>
                            <Button variant='contained' color="primary" onClick={addNewTask}>Ok</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

            <CustomDialog
                open={openDialog}
                onClose={closeDialog}
                success={success}
                error={error}
                Content={success ? "Task Added Successfully" : "Failed To Add Task"} />
        </React.Fragment >
    );
}

export default AddTaskForm;