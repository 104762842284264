/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 701            | 22-12-2023     |    vijaykumar.S   |      Integrate Delete api  
* 609,508        | 27-03-2024     |  shiyamkumar      |  Dynamic Price Change Issue Fixed
* 1729           |  29/4/24       |   Vanitha       |  Cart dialog overlap alignment   changes

*/
import { useEffect, useRef, useState } from 'react';
import {
  DialogContent, DialogContentText, DialogActions, Button,
  DialogTitle, Dialog, Box, Card, Grid, Tooltip, Typography, Alert, IconButton, Stack, Rating, Snackbar, Backdrop, CardContent
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import "./CartDialog.scss";
import { useNavigate } from "react-router";
import SalesmanCalendar from '../calendar/SalesmanCalendar';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import React from 'react';
import { deleteBidById, getCartList, revalidate } from '../../services/BidService';
import { isUserHasPermission, setCurrentPageDetails } from '../../hooks/UserSession';
import StarIcon from '@mui/icons-material/Star';
import gmslogo from "../../assets/logo.gif";
import { useLocation } from 'react-router-dom';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { unescape } from 'querystring';
import { getUnPaidInvoice } from '../../services/InvoiceServices';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  onCount: () => void;
}
const libraries: any = ['places'];

const Cart: React.FC<any> = (props: SimpleDialogProps) => {
  const { onClose, open, onCount } = props;
  const [openSignup, setOpenSignup] = useState(false);
  const [value, setValue] = useState<number | null>(2);
  const [cartData, setCartData] = useState<any>();
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState<any | null>();
  const [openToast, setOpenToast] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState({
    message: '',
    security: ''
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  let navigate = useNavigate();
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [assetId, setAssetId] = useState(null);
  const [loading, setLoading] = useState(true);
  const bidStatus = ["bidPending", "bidReview", "awaitingEstimation", "Contract Approval", "Contract Upload"];
  const [unPaid, setUnPaid] = useState<any[]>([]);

  const handleDialogOpen = (id: any) => {
    setAssetId(id);
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSuccess(false);
    setError(false);
  };

  const addContract = (project: any) => {
    handleClose();
    navigate("/contract", {
      state: { project }
    });
  }

  const fetchUnPaidDetails = async () => {

    try {

      const response = await getUnPaidInvoice("Un Paid", accessToken);
      setUnPaid(response.data);
    } catch (error) {
      console.error('Error fetching user session:', error);

    }
  };

  const fetchRows = async () => {
    //console.log(props)
    const response = await getCartList(accessToken, bidStatus);
    console.log("cartDetails cart data", response)
    setCartData(response?.data);
    setLoading(false);
    ////console.log(cartData.bid_invalid)
    if (response?.data?.bid_invalid) {
      setShow(true);
    }
    fetchUnPaidDetails();
  };

  const dateFormatter = (value: any) => {
    const date = new Date(value);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
  };

  useEffect(() => {
    fetchRows();
  }, [open]);

  const handleCheckout = (selectedCustomerAsset: any) => {
    ////console.log(selectedCustomerAsset)
    // Set openSignup to true
    let data = selectedCustomerAsset;
    setSelectedData(data);
    setOpenSignup(true);
  };

  const validateBid = async (bid: any) => {
    setIsLoading(true);
    const index = cartData.indexOf(bid);
    await revalidate(bid.id, accessToken).then(res => {
      bid = res.data
      let bids = Object.assign([], cartData);
      if (index !== -1) {
        bids[index] = bid;
      }
      console.log(bids);
      setCartData(bids);
      setIsLoading(false);
    })
  };


  const handleClose = () => {
    onClose();
  };

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };


  const deleteBid = async (projectId: any) => {
    let result = await deleteBidById(projectId, "delete", accessToken);
    if (result.status === 204 || result.status === 201) {
      setSuccess(true);
      fetchRows();
      setOpenDialog(false);
    }
    else {
      setError(true)
      setOpenDialog(false);
    }
    onCount();
  };


  const handleEdit = (data: any, salesDate: any) => {
    onClose();
    if (location.pathname === "/createbid" && (window.sessionStorage.getItem("reload") === null || window.sessionStorage.getItem("reload") === "true")) {
      window.sessionStorage.setItem("reload", "false");
      navigate('/createbid', { state: { data: data, state: "edit", salesDate: salesDate } });
      window.location.reload();
    } else {
      navigate('/createbid', { state: { data: data, state: "edit" } });
    }
  };

  const containerStyle = {
    width: '100%',
    height: '220px'
  };

  const [map, setMap] = React.useState(null);
  const componentData = React.useRef<any>();


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
    libraries: libraries
  });

  const convertLocation = (locationString: any) => {
    let locationObject: any = {}
    let string = locationString;
    let st = string?.split(",");
    if (st) {
      locationObject['lat'] = Number(st[0]);
      locationObject['lng'] = Number(st[1]);
    }

    return locationObject;
  };

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const invoiceForm = (rowData: any) => {
    handleClose();
    setCurrentPageDetails(location.pathname, undefined, undefined);
    navigate("/soldproduction/projectinformation/invoiceview", { state: { rowData } });
  };

  return (
    <>
      <Snackbar open={openToast} autoHideDuration={2000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
        <Alert onClose={handleToastClose} security={toastMessage.security} sx={{ width: '100%' }}>
          {toastMessage.message}
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        // onClose={handleClose}
        //onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} >
              <Stack direction="row" >
                <ShoppingCartIcon />
                <Typography className='cartheader'>Cart</Typography>
              </Stack>
            </Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon sx={{
                  '&:hover': {
                    color: 'red',
                  },
                }} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#f8f7fa", padding: "51px 60px" }} >
          <DialogContentText>
            {loading ? (
              <Typography align="center" sx={{ fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: "bold" }}    >
                Loading... <CircularProgress sx={{ marginLeft: '8px' }} />
              </Typography>

            ) : (
              <>
                {cartData !== null && cartData?.length === 0 &&
                  <Card className='cartcard'>
                    <Box justifyContent={'center'} textAlign={'center'} padding={8}>
                      <ShoppingCartIcon sx={{ fontSize: '45px' }} />
                      <Typography sx={{ fontSize: "20px" }}>Your cart is empty!</Typography>
                    </Box>
                  </Card>
                }
                {cartData !== undefined && cartData !== '' && cartData?.map((data: any) => {
                  return (
                    <Card className='cartcard'>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 4, sm: 2, md: 1 }} >
                        <Grid item xs={12} sm={3} md={4}>
                          <Typography className='tradetitle' sx={{ padding: "4px" }} variant='h3'>{data?.trade}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} md={8} textAlign={"end"}>
                          <Stack direction="row" sx={{ float: 'right' }}>
                            {isUserHasPermission("ModifyBid") && (<Tooltip title="Edit">
                              <IconButton onClick={() => handleEdit(data, data?.salesStartDate)} sx={{
                                '&:hover': {
                                  color: 'green',
                                },
                              }}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            )}
                            {isUserHasPermission("DeleteBid") && (<Tooltip title="Delete">
                              <IconButton onClick={() => handleDialogOpen(data.id)} sx={{
                                '&:hover': {
                                  color: 'red',
                                },
                              }}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                          {/* <Typography className='tradetitle'>{data.trade}</Typography> */}
                          <Card>
                            <CardContent sx={{ padding: "0px !important" }}>
                              {isLoaded ? (
                                <GoogleMap
                                  mapContainerStyle={containerStyle}
                                  center={convertLocation(data?.location)}
                                  zoom={20}
                                  onUnmount={onUnmount}
                                  options={{
                                    zoomControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                    keyboardShortcuts: false
                                  }}
                                >
                                  <MarkerF
                                    position={convertLocation(data?.location)}

                                  />
                                  <></>
                                </GoogleMap>
                              ) : <></>}
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} sm={3} md={7} sx={{ marginLeft: "20px" }} alignItems={"center"} justifyItems={"center"} display={"grid"}>
                          <Grid container rowSpacing={1} columnSpacing={1} >
                            <Grid item md={4} >
                              <Stack direction={"column"} spacing={1}>
                                <Typography sx={{ fontWeight: 500 }}>Sales Associate Name:</Typography>
                                <Typography className='highlightText'> {data?.salesPersonName !== null ? data?.salesPersonName : "---"}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item md={4} >
                              <Stack direction={"column"} sx={{ marginLeft: "20px" }} spacing={1}>
                                <Typography sx={{ fontWeight: 500 }}>Date Of AppointMent:</Typography>
                                <Typography className='highlightText'>{data?.salesStartDate !== null ? dayjs(data?.salesStartDate).format("DD-MM-YYYY") : "---"}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item md={4} >
                            </Grid>
                            <Grid item md={4} >
                              <Stack direction={"column"} spacing={1}>
                                <Typography sx={{ fontWeight: 500 }}>Address:</Typography>
                                <Typography className='highlightText'>{data?.address},<br />
                                  {data?.city} ,{data?.state}, <br />{data?.country}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item md={4} >
                              <Stack direction={"column"} sx={{ marginLeft: "20px" }} spacing={1}>
                                <Typography sx={{ fontWeight: 500 }}>Zipcode:</Typography>
                                <Typography className='highlightText'> {data?.zipcode}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item md={4} >
                              <Typography variant='h5' sx={{ color: "#3a3541de", fontWeight: 400 }}>
                                <Stack direction={"column"} sx={{ marginLeft: "20px" }} spacing={1}>
                                  <Typography sx={{ fontWeight: 500 }}>Status:</Typography>
                                  <Typography className='highlightText'>{(data?.status).split(/(?=[A-Z])/).join(" ").replace(/^./, data?.status[0].toUpperCase())}</Typography>
                                </Stack>
                              </Typography>
                            </Grid>
                            {(data?.jobStartDate !== null || data?.newStartDate !== undefined) && <Grid item md={4} >
                              <Stack direction={"column"} spacing={1}>
                                <Typography sx={{ fontWeight: 500 }}>Starts On:</Typography>
                                <Stack direction={"column"} >
                                  <Typography sx={{ color: "#3a3541de", fontWeight: 300, }} variant='h5' className='highlight' >
                                    {data?.jobStartDate !== null && (data?.newStartDate === null || data?.newStartDate === undefined)
                                      ? dateFormatter(data?.jobStartDate) : data?.jobStartDate !== null && (data?.newStartDate !== undefined || data?.newStartDate !== null) ?
                                        dateFormatter(data?.newStartDate) : "---"}
                                  </Typography>
                                  {data?.newStartDate !== null ?
                                    <Typography className='oldvalue'>{dateFormatter(data?.jobStartDate)}</Typography> : null}
                                </Stack>
                              </Stack>
                            </Grid>}

                            {(data?.jobEndDate !== null || data?.newEndDate !== undefined) && <Grid item md={4} >
                              <Stack direction={"column"} sx={{ marginLeft: "20px" }} spacing={1}>
                                <Typography sx={{ fontWeight: 500 }}>Ends On:</Typography>
                                <Stack direction={"column"}>
                                  <Typography sx={{ color: "#3a3541de" }} className='highlight' >
                                    {data?.jobEndDate !== null && (data?.newEndDate === null || data?.newEndDate === undefined) ?
                                      dateFormatter(data?.jobEndDate) : data?.jobEndDate !== null && (data?.newEndDate !== undefined || data?.newEndDate !== null) ?
                                        dateFormatter(data?.newEndDate) : "---"}</Typography>
                                  {data?.newEndDate !== null ? <Typography className='oldvalue'>{dateFormatter(data?.jobEndDate)}</Typography> : null}
                                </Stack>
                              </Stack>
                            </Grid>}
                            {(data.totalCost !== null || data.newCost !== undefined) && <Grid item md={4}>
                              <Stack direction={"column"} spacing={1} sx={{ marginLeft: "20px" }} >
                                <Typography sx={{ fontWeight: 500 }}>Cost:</Typography>
                                <Stack direction={"column"}>
                                  <Typography sx={{ color: "#3a3541de", fontWeight: 400, }} className='highlight'>
                                    {data?.totalCost !== null && (data?.newCost === null || data?.newCost === undefined) ? "$" + Math.round(data?.totalCost) :
                                      data?.totalCost !== null &&
                                        (data?.newCost !== null || data?.newCost !== undefined) ? "$" + Math.round(data?.newCost) : "---"}</Typography>
                                  {data?.newCost !== null ? <Typography className='oldvalue'> $ {Math.round(data?.totalCost)}</Typography> : null}
                                </Stack>
                              </Stack>
                            </Grid>}
                          </Grid>
                        </Grid>
                        <Grid item md={10}>
                          {data?.invalid ? <Alert severity="warning">There has been change in price/schedule change. Click refresh to view the revised price/schedule</Alert> : null}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>

                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={6}>
                              {/* <Alert severity="warning" sx={{ padding: 0 }}>
                                Cost and Schedule are subject to availability
                              </Alert> */}
                            </Grid>
                            <Grid item xs={4} className='alignEnd'>
                              {
                                data?.invalid === true ?
                                  <Button variant='contained' onClick={() => validateBid(data)}>Refresh</Button> :
                                  data?.status === "Contract Upload" && <Button variant='contained' onClick={() => addContract(data)}>Contract</Button>
                              }
                              {/* {
                                (data?.status === "Contract Approval" && data?.invalid === false) ?
                                  <></> :
                                  <Button variant='contained' onClick={() => validateBid(data)}>Refresh</Button>
                              } */}
                              {/* {data?.invalid === false ? <></> :
                            <Button variant='contained' onClick={() => validateBid(data)}>Refresh</Button>} */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  )
                })}
              </>
            )}
          </DialogContentText>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={handleClose}
          >
            <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
          </Backdrop>
        </DialogContent>
      </Dialog>
      <SalesmanCalendar props={selectedData} open={openSignup} handleClose={() => setOpenSignup(false)} />
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
          <DialogContent >
            <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
              Are you sure?
            </Typography>
            <Typography textAlign={'center'}>
              You won't be able to revert this!
            </Typography>
          </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
          <Button onClick={() => deleteBid(assetId)} autoFocus className="primaryTonalButton">
            Yes, Delete it!
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={success} >
        <DialogTitle className='success'>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
              Success
            </Typography>
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Bid has been Deleted Successfully</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={error} >
        <DialogTitle className='error'>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
              Error
            </Typography>
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Error Couldn't Delete Bid</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}


export default Cart;