import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import * as React from 'react';
import { getPredecessorTasks, getSuccessorTasks, getTaskTypeList } from '../../services/TaskService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TaskForm: React.FC<any> = (props) => {
    const [predecessorTask, setPredecessorTask] = React.useState<any>([]);
    const [successorTask, setSuccessorTask] = React.useState<any>([]);
    const [taskTypes, setTaskTypes] = React.useState<any>([]);
    const { accessToken } = useOidcAccessToken();
    const taskStatus = ["new", "scheduled", "active"];

    const handleChange = (event: any) => {
        props.changeTaskData(event);
    }

    const getSuccessorTask = async () => {
        let result = await getSuccessorTasks(props?.projectId, props?.taskId, taskStatus, accessToken);
        setSuccessorTask(result?.data);
    }

    const getPredecessorTask = async () => {
        let result = await getPredecessorTasks(props?.projectId, props?.taskId, taskStatus, accessToken);
        setPredecessorTask(result?.data);
    }

    const getTaskTypes = async () => {
        let result = await getTaskTypeList(accessToken);
        setTaskTypes(result.data);
    }

    React.useEffect(() => {
        if (props?.taskId !== "null") {
            getSuccessorTask();
            getPredecessorTask();
        }
        console.log(props);
        getTaskTypes();
    }, []);

    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="demo-helper-text-aligned"
                            label="Task Name"
                            name="taskName"
                            size="small"
                            value={props?.task?.taskName}
                            error={(props?.task?.taskName === '' && props?.count !== 0)}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="demo-helper-text-aligned"
                            label="Duration"
                            name="estimatedHours"
                            size="small"
                            fullWidth
                            value={props?.task?.estimatedHours}
                            error={(props?.task?.estimatedHours === '' && props?.count !== 0)}
                            disabled={(props?.task?.phase === "sales" ? true : false)}
                            type="number"
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label" error={(props?.task?.taskType === '' && props?.count !== 0)} required>Task Type</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="taskType"
                            value={props?.task?.taskType}
                            error={(props?.task?.taskType === '' && props?.count !== 0)}
                            label="Task Type"
                            onChange={(e) => handleChange(e)}
                            MenuProps={MenuProps}
                            required
                        >
                            {taskTypes?.map((type: any, index: any) => {
                                return (
                                    <MenuItem key={index} value={type}>{(type)?.split(/(?=[A-Z])/).join(" ").replace(/^./, type[0]?.toUpperCase())}</MenuItem>
                                )
                            })}


                        </Select>
                            {/* <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                name="taskType"
                                value={props?.task?.taskType}
                                error={((props?.task?.taskType).length === 0 && props?.count !== 0)}
                                label="Task Type"
                                onChange={(e) => handleChange(e)}
                                input={<OutlinedInput label="Task Type" />}
                                renderValue={(selected) => selected.join(",")}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                            >
                                {taskTypes?.map((type: any, index: any) => {
                                    return (
                                        <MenuItem key={index} value={type}>{(type)?.split(/(?=[A-Z])/).join(" ").replace(/^./, type[0]?.toUpperCase())}</MenuItem>
                                    )
                                })}
                            </Select> */}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {predecessorTask?.length === 0 ? null :
                            <>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">Set Predecessor Task</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={props?.task?.dependencies}
                                        label="Set Predecessor Task"
                                        name="dependencies"
                                        onChange={(e) => handleChange(e)}
                                        input={<OutlinedInput label="Set Predecessor Task" />}
                                        renderValue={(selected) => selected.join(",")}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                                    >
                                        {predecessorTask?.map((task: any, index: any) => {
                                            return (
                                                <MenuItem key={index} value={task?.taskId}>{task?.taskName}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl></>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {successorTask?.length === 0 ? null :
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Set Successor Task</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={props?.task?.otherDependencies}
                                    label="Set Successor Task"
                                    name="otherDependencies"
                                    onChange={(e) => handleChange(e)}
                                    input={<OutlinedInput label="Set Successor Task" />}
                                    renderValue={(selected) => selected.join(",")}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                                >
                                    {successorTask?.map((task: any, index: any) => {
                                        return (
                                            <MenuItem key={index} value={task?.taskId}>{task?.taskName}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default TaskForm;