/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1557           |  10/04/24   | shiyam Kumar  |  Service Layer For TradeConfiguration
*/


import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const getTrades = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades`;
  return await executeGetData(url, accessToken);
}

export const addTrade = async (payload: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades`;
  return await executePostData(url, payload, accessToken);
}

export const updateTrade = async (id: any, payload: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/${id}`;
  return await executePutData(url, payload, accessToken);
}

export const getTradeNames = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/getalltrades`;
  return await executeGetData(url, accessToken);
}

export const deleteTrade = async (tradeId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/${tradeId}`;
  return await executeDeleteData(url, accessToken);
}
export const validQuestionTrade = async( accessToken:any) =>
  {
    const url = `${ConfigUrl.baseURL}/trades/getValidTradeName`;
    return await executeGetData(url, accessToken);
  }

export const getConfiguredDataByKey = async (key:any,accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations/value/${key}`;
    return await executeGetData(url, accessToken);
}

export const getPreconstructionPeriod = async (key: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations/value/${key}`;
    return await executeGetData(url, accessToken);
}
  