import { Card, Fab, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Stack, TextField, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useNavigate } from 'react-router-dom';
import { getToolNamesByTrade } from '../../services/ToolService';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddToolForm from '../toolManagement/AddToolForm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const type = ["On Start", "On Completion"]

const action = ["Customer Contract Signing", "Sign Contract Remainder Email", 
    "Upload",  "Pay Invoice Remainder Email", "Confirm", "Reject", "Time Out", "Attach"
]

const item = ["Document", "Po", "Invoice", "Bill", "Delivery Slip", "Contract", "Bom", "Receipt"]

const ActionForm: React.FC<any> = (props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuIndex, setMenuIndex] = React.useState<any>();
    const openMenu = Boolean(anchorEl);

    const handleChange = (event: any, index: any) => {
        props?.changeTaskData(event, index);
    };

    const addRow = (index: any) => {
        setAnchorEl(null);
        props.add();
    }
    const removeRow = (index: any) => {
        setAnchorEl(null);
        props.remove(index);
    }
    const handleClick = (event: any, index: any) => {
        setMenuIndex(index);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }
    React.useEffect(() => {
    }, []);

    return (
        <>
            <Grid container className='alignCenter'>
                <Grid item xs={12}>
                <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, height:'34vh', overflow:'auto'}}>
                    {props?.task?.actions?.map((act: any, index: any) =>
                        <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, backgroundColor: '#F1F3FF' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={act?.state}
                                            label="State"
                                            name="state"
                                            onChange={(e) => handleChange(e, index)}
                                        >
                                            {type?.map((type: any, key: any) => {
                                                return (
                                                    <MenuItem key={key} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                );
                                            }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="demo-simple-select-label">Action to be Taken</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={act?.action}
                                            label="Action to be Prefer"
                                            name="action"
                                            onChange={(e) => handleChange(e, index)}
                                        >
                                            {action?.map((type: any, key: any) => {
                                                return (
                                                    <MenuItem key={key} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                );
                                            }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    {act?.action === "Attach" ?
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="demo-simple-select-label">Item</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={act?.item}
                                            label="Item"
                                            name="item"
                                            onChange={(e) => handleChange(e, index)}
                                        >
                                            {item?.map((type: any, key: any) => {
                                                return (
                                                    <MenuItem key={key} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                );
                                            }
                                            )}
                                        </Select>
                                    </FormControl>
                                    :
                                    null
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        id="demo-positioned-button"
                                        aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? 'true' : undefined}
                                        onClick={(e) => handleClick(e, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl}
                                        open={openMenu === true && menuIndex === index ? true : false}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MenuItem>
                                            <Stack direction={'row'} spacing={1}>
                                                <EditIcon fontSize='small'
                                                />
                                                <Typography>Edit</Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem onClick={() => removeRow(index)}>
                                            <Stack direction={'row'} spacing={1}>
                                                <DeleteIcon fontSize='small' />
                                                <Typography>Delete</Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem onClick={() => addRow(index)}>
                                            <Stack direction={'row'} spacing={1}>
                                                <AddIcon fontSize='small' />
                                                <Typography>Add</Typography>
                                            </Stack>
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Card>
                    )}
                     </Card>
                </Grid >
            </Grid >
        </>
    );
}

export default ActionForm;