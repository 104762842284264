/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 1-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1584          |  6/4/24    |   Nandha      | Gps permission implements
* 1792          |  4/5/24    |   Vanitha     | Remove the Upload Document button 
* 1872          |  09/05/24  |   Vanitha     | Signed contract is remove in under document tab(for crew) implement
* 2265          |  17/06/24  | Shiyam Kumar  | Project Hold And Resume Workflow Implementated 
* 2425          |  31/07/24  | Shiyam Kumar  | Project pasuse and Resource Assignment Button Condtion Rendered By User Role
*/
import * as React from 'react';
import {
    Box, Grid,
    Typography, useTheme, useMediaQuery,
    Tabs, Tab, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Backdrop,
    Button
} from "@mui/material";
import { useLocation } from 'react-router-dom';
import Header from "../components/Header";
import TaskList from '../components/task/TaskList';
import BOMList from '../components/bom/BOMList';
import POList from '../components/purchaseOrder/POList';
import DocumentList from '../components/document/DocumentList';
import DeliveryList from '../components/deliverySlip/DeliverySlip';
import BillList from '../components/Bill/billList/BillList';
import TransactionList from '../components/transaction/transactionList';
import InvoiceList from '../components/invoice/InvoiceList';
import ReceiveBillPaymentList from '../components/payments/ReceiveBillPayements';
import { getProjectInformation, getRecentStateChangeHistory, projectReschedulewithDate, updateProjectByProjectId, updateTemplatesData } from '../services/ProjectService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { Stack } from '@mui/system';
import TodayIcon from '@mui/icons-material/Today';
import FactoryIcon from '@mui/icons-material/Factory';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import TimeLogDetails from '../components/TimeLog/TimeLogDetails';
import dayjs, { Dayjs } from 'dayjs';
import { isUserHasPermission, setCurrentPageDetails } from '../hooks/UserSession';
import JobLedgerList from '../components/jobLedger/JobLedgerList';
import WipReport from '../components/wip/WipReport';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import gmslogo from "../assets/logo.gif";
import CustomDialog from './Dialog';
import ProjectMembersList from '../components/projectMember/ProjectMembersList';
import ReferenceProjectList from '../components/projectList/ReferenceProject';
import BidTaskList from '../components/bidDetails/BidTaskList';
import QuestionRenderer from './QuestionRenderer/QuestionRenderer';
import Taxsettings from './TaxSettings';
import Paymentsettings from './PaymentSettings';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    xyz: string;
    data: string;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, data, index, xyz, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={data !== xyz}
            id={`simple-tabpanel-${xyz}`}
            aria-labelledby={`simple-tab-${xyz}`}
            {...other}
        >
            {data === xyz && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ProjectInformation: React.FC = () => {
    const theme = useTheme();
    const { accessToken } = useOidcAccessToken();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [value, setValue] = React.useState<any>();
    const location = useLocation();
    const { state } = location;
    const [odmData, setOdmData] = React.useState<any>(null);
    const [odmProjectData, setOdmProjectData] = React.useState<any>(null);
    const bidReview = "bidReview";
    const awaitingEstimation = "awaitingEstimation";
    const contractApproval = "Contract Approval";
    const projectActive = "Project Active";
    const onHold = "On Hold";

    const questionRendererRef = React.useRef<any>(null);
    const rowData = state?.rowData;
    const status = state?.status;
    const user = state?.user;
    const projectView = state?.projectView;

    // Ensure onDataReceived is defined and is a function before calling it





    const [data, setData] = React.useState(() => {
        if (user.role.name === "sales") {
            return "simple-tab-0";
        } else if (user.role.name === "purchaseOfficer") {
            return "simple-tab-3";
        }
        else if (user.role.name === "customer") {
            return "simple-tab-8";
        } else {
            return "simple-tab-1";
        }
    });


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        var x = event.currentTarget.id;
        setCurrentPageDetails(location.pathname, newValue, event.currentTarget.id);
        setValue(newValue);
        setData(x);
    };

    const handleTabChange = (data: any, value: any) => {
        getProjectInformationData("bidView");
        setValue(value);
        setData(data);
    }

    const [open, setOpen] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState();
    const [preview, setPreview] = React.useState();
    const [view, setView] = React.useState('');
    const [projectData, setProjectData] = React.useState({} as any);
    const [openCalendar, setOpenCalendar] = React.useState<any>(false);
    const [selectedDate, setSelectedDate] = React.useState<any>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [reason, setReason] = React.useState('');
    const [openReasonDialog, setOpenReasonDialog] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [statusHistory, setStatusHistory] = React.useState<any>();
    const [openReferenceProject, setOpenReferenceProject] = React.useState(false);

    const handleHold = () => {
        setOpenReasonDialog(true)
    };


    const handleClose = () => {
        setOpenReasonDialog(false);
        setReason('');
    };

    const onChange = async (event: any) => {
        const file = event.target.files[0];
        onSelectFile(file);
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        setOpenDialog(false);
    }

    const closeReferenceProject = () => {
        setOpenReferenceProject(false);
    }

    const getProjectInformationData = async (name: any) => {
        let result = await getProjectInformation(rowData, name, accessToken);
        if (result.status === 200 || result.status === 201) {
            setProjectData(result.data);
        }

        let reason = await getRecentStateChangeHistory(rowData, accessToken);
        if (result.status === 200 || result.status === 201) {
            setStatusHistory(reason.data);
        }
        ;
    }

    const updateProject = async (status : any) =>
        {
            let result = await updateProjectByProjectId(rowData, user.firstname +' '+ user.lastname, reason, status, accessToken);
            if(result.status === 200 || result.status === 201){
                getProjectInformationData("projectView");
                setOpenReasonDialog(false);
                setReason('');
                setMessage("Status Changed");
                setOpenDialog(true);
                setSuccess(true);

        } else {
            setOpenReasonDialog(false);
            setMessage("change Status");
            setOpenDialog(true);
            setError(true);
        }
    }

    React.useEffect(() => {
        if (state?.selectedTab !== undefined) {
            setValue(state?.selectedTab);
            if (state?.tabName !== undefined) {
                setData(state?.tabName);
            }
        } else {
            setValue(0);
            setCurrentPageDetails(location.pathname, 0, undefined);
        }

        if (projectView === true) {
            getProjectInformationData("projectView");
        }
        else {
            getProjectInformationData("bidView");
        }

        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setView(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)

    }, [selectedFile])

    const onSelectFile = (file: any) => {
        if (!file || file === 0) {
            setSelectedFile(undefined)
            return
        }
    }

    const resumeProject = (event: any) => {
        setSelectedDate(event.$d);
    }

    const rescheduleProject = async () => {
        setOpenCalendar(false);
        setIsLoading(true);
        const result = await projectReschedulewithDate(rowData, dayjs(selectedDate).hour(9).minute(0).format('YYYY-MM-DDTHH:mm') + 'Z', "resume", accessToken);
        if((result.status === 200 || result.status === 201) && result.data === "Successful"){
            setMessage("Project Rescheduled");
            setIsLoading(false);
            setOpenDialog(true);
            setSuccess(true);
            let res = await updateProjectByProjectId(rowData, user.firstname + ' ' + user.lastname, "rescheduled", projectActive, accessToken);
            if (res.status === 200 || res.status === 201) {
                getProjectInformationData("projectView");
            }
        } else {
            setMessage("Reschedule");
            setIsLoading(false);
            setOpenDialog(true);
            setError(true);
        }
    }

    const holdProject = () => {
        updateProject(onHold);
        // getProjectInformationData();
        // setOpenReasonDialog(true);
    }

    const callParentComponent = () => {
        if (questionRendererRef.current && typeof questionRendererRef.current.handleSaveFunctionality === 'function') {
            questionRendererRef.current.handleSaveFunctionality();
        }
    };
    const handleReasonChange = (event: any) => {
        setReason(event.target.value);
    }

    const isDateDisabled = (date: Dayjs) => {
        const today = dayjs();  // Get the current date
        // Disable dates up to and including today


        if (date.isBefore(today, 'day') || date.isSame(today, 'day')) {
            return true;
        }

        return date.day() === 6 || date.day() === 0;
    };

    const handleLoadingClose = () => {
        setIsLoading(false);
    }

    const handleSaveFunctionality = async (savedAssetData: any, savedProjectData: any, savedTradeType: any, savedTradeVersion: any, savedAssetVersion: any, assetOid: any, tradeOid: any) => {
        setOdmData(savedAssetData);
        setOdmProjectData(savedProjectData);
        const templateData = {
            answersData: savedAssetData,
            tradeOid: assetOid
            // status: awaitingEstimation
        };
        const projectData = {
            answersData: savedProjectData,
            tradeOid: tradeOid
        }
        await updateTemplatesData(rowData, templateData, accessToken).then((res) => {
            if (res.status && res.data === true) {
                // setMessage("Bid Details updated");
                setIsLoading(false);
                // setOpenDialog(true);
                // setSuccess(true);
                setOpenReferenceProject(true);
            }
            else {
                setMessage("Updation");
                setIsLoading(false);
                setOpenDialog(true);
                setError(true);
            }
        })

        await updateTemplatesData(rowData, projectData, accessToken).then((res) => {
            if (res.status && res.data === true) {
                // setMessage("Bid Details updated");
                setIsLoading(false);
                // setOpenDialog(true);
                // setSuccess(true);
                setOpenReferenceProject(true);
            }
            else {
                setMessage("Updation");
                setIsLoading(false);
                setOpenDialog(true);
                setError(true);
            }
        })

        // setAssetData({ ...assetData, assetTemplatedata:"sample"});
        // setAssetData({ ...assetData, assetTemplatedata:"sample"});


    };
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={handleLoadingClose}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                {/* <Typography className="loading" variant='h4' color="blue" >Loading . . .</Typography> */}
            </Backdrop>
            <Box>
                <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                    <Grid className='cardBoxShadow' item xs={12}>
                        {/* HEADER */}
                        <Box
                            display={smScreen ? "flex" : "block"}
                            flexDirection={smScreen ? "row" : "column"}
                            justifyContent={smScreen ? "space-between" : "start"}
                            alignItems={smScreen ? "center" : "start"}
                            m="10px 0"
                        >
                            <Header title={projectView === false ? "Bid Details" : "Project Information"} subtitle="" />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            {projectView === false ? null :
                                <Grid container spacing={2} p={1}>

                                    <Grid item md={4}>
                                        <Stack direction={"column"} spacing={1}>
                                            <Stack direction={"row"} spacing={1}>
                                                <DataThresholdingIcon className='iconheading' />
                                                <Typography className='highlightheading'>Project Name:</Typography>
                                            </Stack>
                                            <Typography className='highlightsubheading'>{projectData?.trade}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack direction={"column"} spacing={1} sx={{ marginLeft: "-30px" }}>
                                            <Stack direction={"row"} spacing={1}>
                                                <FactoryIcon className='iconheading' />
                                                <Typography className='highlightheading'>Manufacturer:</Typography>
                                            </Stack>
                                            <Typography className='highlightsubheading'>{projectData?.manufacturer}

                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack direction={"column"} spacing={1} alignItems={"center"}>
                                            <Stack direction={"row"} spacing={1}>
                                                <RequestQuoteIcon className='iconheading' />
                                                <Typography className='highlightheading'>Estimated Cost:</Typography>
                                            </Stack>
                                            <Typography className='highlightsubheading'>${projectData?.cost}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack direction={"column"} spacing={1} alignItems={"center"}>
                                            <Stack direction={"row"} spacing={1}>
                                                <TodayIcon className='iconheading' />
                                                <Typography className='highlightheading'>Start Date:</Typography>
                                            </Stack>
                                            <Typography className='highlightsubheading' sx={{ marginLeft: "2px" }}>{dayjs(projectData?.startDate).format("DD MMM YYYY")}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} spacing={1}>
                                        <Stack direction={"column"} spacing={1}>
                                            <Stack direction={"row"} spacing={1}>
                                                <AccountCircleIcon className='iconheading' />
                                                <Typography className='highlightheading'>Customer:</Typography>
                                            </Stack>
                                            <Typography className='highlightsubheading'>{projectData?.customer}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack direction={"column"} spacing={1} sx={{ marginLeft: "-30px" }}>
                                            <Stack direction={"row"} spacing={1}>
                                                <ProductionQuantityLimitsIcon className='iconheading' />
                                                <Typography className='highlightheading'>Material Type:</Typography>
                                            </Stack>
                                            <Typography className='highlightsubheading'>{projectData?.materialType}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={3}>
                                        <Stack direction={"column"} spacing={1} alignItems={"center"}>
                                            <Stack direction={"row"} spacing={1}>
                                                <TodayIcon className='iconheading' />
                                                <Typography className='highlightheading'>End Date:</Typography>
                                            </Stack>
                                            <Typography className='highlightsubheading'>{dayjs(projectData?.endDate).format("DD MMM YYYY")}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
  
                            }
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
                                <Stack direction="row" spacing={1} justifyContent='space-between'>
                                    <Box>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                            {/* {isUserHasPermission("OverView") &&
        <Tab label="Overview" {...a11yProps(0)} />
    } */}
                                            {projectView === false && <Tab label="QA" {...a11yProps(0)} />}

                                            {(projectView === true ?
                                                isUserHasPermission("ProjectInfoTask") &&  <Tab label="Tasks" {...a11yProps(1)} /> :
                                                projectData.status === awaitingEstimation && <Tab label="Gantt Tasks" {...a11yProps(1)} />)
                                            }

                                            {(projectData.status !== bidReview && isUserHasPermission("ProjectInfoBom")) &&
                                                <Tab label="BOM" {...a11yProps(2)} />}

                                            {isUserHasPermission("ProjectInfoPo") &&
                                                <Tab label="PO" {...a11yProps(3)} />
                                            }

                                            {isUserHasPermission("ProjectInfoDeliverySlip") &&
                                                <Tab label="Delivery slips" {...a11yProps(4)} />}
                                            {isUserHasPermission("ProjectInfoBill") &&
                                                <Tab label="Bill" {...a11yProps(5)} />}

                                            {/* <Tab label="Transaction" {...a11yProps(6)} /> */}

                                            {projectView === true && (isUserHasPermission("Invoice") && user.role.name === 'admin' || user.role.name === 'purchaseOfficer') && (
                                                <Tab label="Invoice" {...a11yProps(7)} />)}
                                            {isUserHasPermission("PaymentRead") &&
                                                <Tab label="Payments" {...a11yProps(8)} />}
                                            {isUserHasPermission("ProjectInfoJobLedger") &&
                                                <Tab label="Job Ledger" {...a11yProps(9)} />}
                                            {/* {isUserHasPermission("TimeSheet") &&
                                                <Tab label="Time Sheet" {...a11yProps(10)} />} */}
                                            {(isUserHasPermission("Document") && projectView === true ) &&
                                                <Tab label="Documents" {...a11yProps(11)} />}
                                            {isUserHasPermission("WipReport") &&
                                                <Tab label="Wip Report" {...a11yProps(12)} />}
                                            {(projectView === false && projectData.status === contractApproval) && <Tab label="Tax" {...a11yProps(13)} />}

                                            {projectView === false && projectData.status === bidReview && <Tab label="Payment Settings" {...a11yProps(14)} />}
                                        </Tabs>
                                    </Box>
                                    {user.role.name === "admin" &&
                                        <><Stack direction="row" justifyContent={"flex-end"}>

                                        </Stack>

                                            <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                                {(projectData?.status === projectActive || projectData?.status === onHold) && <Box>
                                                    {projectData?.status === onHold ? <Button variant='contained' color="primary" onClick={() => setOpenCalendar(true)}>Resume</Button>
                                                        :
                                                        <Button variant='contained' color="primary" onClick={() => handleHold()} size='small'>Hold</Button>}

                                                </Box>}
                                            </Stack>
                                            <Stack direction="row" justifyContent={"flex-end"}>

                                            </Stack></>
                                    }
                                </Stack>
                            </Box>

                            {(statusHistory !== undefined && projectData?.status === onHold) && <Grid container p={1}>
                                <Grid xs={4}></Grid>
                                <Grid xs={4}><Typography textAlign={'center'} sx={{ backgroundColor: "#efef8d", borderRadius: 10 }}>*Reason For Hold: {statusHistory.changeReason}</Typography></Grid>
                                <Grid xs={4}></Grid>
                            </Grid>}
                            <CustomTabPanel value={value} index={0} data={data} xyz="simple-tab-0">
                            {projectView === false && <><Box style={{ display: 'flex' }}>
                                    <QuestionRenderer ref={questionRendererRef} onSave={handleSaveFunctionality} projectId={rowData} />

                                    <Box className='pr-5' sx={{
                                        justifyContent: 'space-between', alignItems: 'center', position: 'absolute',
                                        bottom: '19px', // Adjust as needed
                                        right: '36px', // Adjust as needed
                                    }}>
                                        {projectData.status === bidReview
                                            ?
                                            <Button variant='contained' onClick={() => callParentComponent()} autoFocus>
                                                Submit
                                            </Button>
                                            :
                                            null}
                                    </Box>
                                </Box><ReferenceProjectList open={openReferenceProject} onClose={closeReferenceProject} projectData={projectData} tabChange={handleTabChange} /></>
                                    }
                            </CustomTabPanel>
                            
                            <CustomTabPanel value={value} index={1} data={data} xyz="simple-tab-1">
                                {projectView === true ?
                                    <TaskList projectId={rowData} render={openReasonDialog} project={projectData}/>
                                    :
                                    <BidTaskList props={projectData} />
                                }
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2} data={data} xyz="simple-tab-2">
                                <BOMList projectId={rowData} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3} data={data} xyz="simple-tab-3">
                                <POList projectId={rowData} status={status} projectView={projectView}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4} data={data} xyz="simple-tab-4">
                                <DeliveryList projectId={rowData} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={5} data={data} xyz="simple-tab-5">
                                <BillList />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={6} data={data} xyz="simple-tab-6">
                                <TransactionList />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={7} data={data} xyz="simple-tab-7">
                                <InvoiceList project={rowData} status={projectData?.status} role={user.role.name} projectView={projectView}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={8} data={data} xyz="simple-tab-8">
                                <ReceiveBillPaymentList />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={9} data={data} xyz="simple-tab-9">
                                <JobLedgerList projectId={rowData} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={10} data={data} xyz="simple-tab-10">
                                <TimeLogDetails projectId={rowData} />
                            </CustomTabPanel>
                            {/* <CustomTabPanel value={value} index={11} xyz="simple-tab-11" data={data}>
        <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
            <Button variant="contained">Upload Document</Button>
        </Grid>
        <DocumentList projectId={rowData} />
    </CustomTabPanel> */}
                            <CustomTabPanel value={value} index={11} xyz="simple-tab-11" data={data}>
                                {/* Adjust the justifyContent to "flex-end" to align the button to the right */}
                                {/* <Grid container justifyContent="flex-end" alignItems="center" mt={3} mb={2} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
        <Button variant="contained" >Upload Document</Button>
    </Grid> */}
                                <DocumentList projectId={rowData} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={12} data={data} xyz="simple-tab-12">
                                <WipReport projectId={rowData} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={13} data={data} xyz="simple-tab-13">
                                {(state?.projectView === false && projectData.status === contractApproval) && <Taxsettings projectId={projectData.id} />}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={14} data={data} xyz="simple-tab-14">
                                {(state?.projectView === false) && <Paymentsettings projectId={projectData.id} />}
                            </CustomTabPanel>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={3}>
        {role !== "customer" ?
        <GPS /> :<></>
                }
    </Grid> */}
                </Grid>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openCalendar}
                >
                    <DialogTitle>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1}>
                                <Typography className='cartheader'>Select Date When You Want to Start Work</Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={() => setOpenCalendar(false)}>
                                    <CloseIcon sx={{ color: "red" }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{ backgroundColor: "#f8f7fa" }}>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
            <DemoItem>
                <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
            </DemoItem>
        </DemoContainer>
    </LocalizationProvider> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                // sx={{
                                //   '.MuiPickersDay-root': {
                                //     color: 'blue',
                                //     backgroundColor: 'grey',
                                //   },
                                // }}
                                // slots={{ day: CustomDay }}//date reange

                                shouldDisableDate={isDateDisabled} //disable properties
                                disablePast
                                disableHighlightToday
                                // defaultValue={dayjs(startDateValidator(new Date(props.props?.startdate)))}//default date is setted
                                value={dayjs(selectedDate)}
                                onChange={resumeProject} />
                        </LocalizationProvider>
                        {/* onClose={handleCloseAlert} */}
                        {/* <Dialog open={showAlert}
    >
       <DialogTitle className='warning'>
        <Box paddingLeft={8} paddingRight={8}>
                <Typography textAlign={'center'}>
                    <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                   Warning
                </Typography>
            </Box>
            </DialogTitle>
         <Box paddingLeft={8} paddingRight={8}>
        <DialogContent style={{marginTop:"10px"}}>
            <DialogContentText sx={{  fontWeight: "500", fontSize: "18px" }}>
                Selected date <span className='warningDate'>{dayjs(selectedDate).format("DD MMM YYYY")}</span> will change your actual construction date .
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{  justifyContent: "center" }}>
        <Button onClick={()=>closeAlter()} variant='contained' sx={{backgroundColor:"#df3333ed"}}>Close</Button>
            <Button onClick={handleCloseAlert}  variant='contained' color="primary">
                Ok
            </Button>
        </DialogActions>
        </Box>
    </Dialog> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenCalendar(false)} color="error">Close</Button>
                        <Button color="primary" onClick={rescheduleProject}>Reschedule</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openReasonDialog}
                    onClose={handleClose}
                >
                    <Box paddingLeft={4} paddingRight={4} paddingTop={2}>
                        <DialogContent >
                            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                                Give a Reason To Status Change
                            </Typography>
                            <Grid container>
                                <Grid xs={3}></Grid>
                                <Grid xs={6}>
                                    <TextField
                                        id="outlined-number"
                                        type="text"
                                        value={reason !== '' ? reason : ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleReasonChange(e)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid xs={3}></Grid>
                            </Grid>
                        </DialogContent>
                    </Box>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button onClick={handleClose} className="dangerButton">Close</Button>
                        <Button disabled={reason === ''} onClick={holdProject} autoFocus className={reason !== '' ? "primaryTonalButton" : ""}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <CustomDialog
                    open={openDialog}
                    onClose={closeDialog}
                    success={success}
                    error={error}
                    Content={success ? message + " Successfully" : "Failed To " + message} />

            </Box></>
    );
};

export default ProjectInformation;